.toastContainer {
  position: fixed;
  bottom: 20px;
  left: 10px;
  right: 10px;
  animation: slideUp 0.3s linear;
  padding: 10px 30px 10px 10px;
  border-radius: 7px;
  box-shadow: 0 2px 4px 0 var(--gray);
}

.errorToast {
  background-color: var(--error);
  color: var(--white);
}

.notificationToast {
  background-color: var(--success);
  color: var(--white);
}

.closeBtn {
  display: inline-block;
  width: 20px;
  margin: 0 10px 0 0;
  position: absolute;
  right: 0;
  padding: 0;
  background-color: transparent;
}

.closeBtn svg path {
  fill: var(--white);
}

@keyframes slideUp {
  from {
    bottom: 0;
  } 
  to {
    bottom: 20px;
  }
}