@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
  }
}

.spinner {
  --spinner-height: 40px;

  height: var(--spinner-height);
  position: relative;
  width: var(--spinner-height);

}

.doubleBounce1,
.doubleBounce2 {
  animation: sk-bounce 2.0s infinite ease-in-out;
  background-color: #333;
  border-radius: 50%;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}

.doubleBounce2 {
  animation-delay: -1.0s;
}

.centered {
  margin: 5vh auto;
}