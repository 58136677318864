.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.modalBackdrop {
  background-color: var(--grey);
  backdrop-filter: blur(2px);
  position: absolute;
  bottom:0;
  top: 0;
  left: 0;
  right: 0;
}

.modalContent {
  width: 85%;
  margin: 0 auto;
  text-align: center;
  padding: 5%;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 var(--grey);
  z-index: 1;
  position: relative;
}

.backArrow {
  position: absolute;
  left: 10px;
  top: 0;
  display: inline-block;
  width: auto;
  background-color: transparent;
  transform: scale(0.85);
}

.inactiveModal {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
}

.inactiveModalSvg {
  margin-left: 50px;
}

.inactiveModalTitle {
  color: var(--primary-blue);
  margin-bottom: 45px;
}

.inactiveModalButton {
  margin-top: 40px;
  width: 250px;
}
