@import "./normalize.css";

@import "./variables.css";

@font-face {
  font-family: 'Dina';
  src: local('Dina'), url(../fonts/DIN_NEXTظ_ت_ARABIC_MEDIUM_.otf) format('opentype');
}

* {
  box-sizing: border-box;
  font-family: var(--default-font-family);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}

html,
body {
  height: 100%;
  margin: 0;
  width: 100%;
}

#root {
  height: 100%;
}

main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--gray);
}
::-moz-placeholder { /* Firefox 19+ */
  color: var(--gray);
}
:-ms-input-placeholder { /* IE 10+ */
  color: var(--gray);
}
:-moz-placeholder { /* Firefox 18- */
  color: var(--gray);
}

/* FONT */

.whiteText {
  color: var(--white);
  font-weight: 600;
}

div.topContainer {
  margin-bottom: 1vh;
}

input, button {
  outline: none;
  width: 100%;
  padding: 1vh;
  margin-top: 0.5vh;
  border-radius: 7px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  border: none;
}

input:focus {
  border: none;
  border-width: 0;
  outline: none;
}

/* override default autofill styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-radius: 0.5em;
  border: none;
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

button:focus {
  outline: none;
}

button:active {
  opacity: 0.7;
}

button:disabled {
  opacity: 0.4;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.stepper {
  width: 100%;
    height: 57px;
  text-align: center;
}

.ltr {
    direction: ltr;
}

.stepper > svg {
 width: 100%;
}

.center {
  text-align: center;
}

h1,
h2,
h3,
h4 {
  font-family: Dina;
}

.pageMainContainer {
  margin-top: 15px;
}

/* DatePicker BaseUI style fix */
ul[role=listbox]:focus {
  outline: var(--light-blue) solid 2px;
}

/* Apple Pay style */
.apple-pay-button {
	width: 250px;
	height: 40px;
	display: inline-block;
	-webkit-appearance: -apple-pay-button;
  cursor: pointer;
  -apple-pay-button-style: white;
}

.apple-pay-button-with-text>* {
	display: none;
}

.apple-pay-button-black-with-text {
	-apple-pay-button-style: white;
}

.apple-pay-button-white-with-text {
	-apple-pay-button-style: black;
}

.apple-pay-button-white-with-line-with-text {
	-apple-pay-button-style: white-outline;
}

.apple-pay-button-text-book {
	-apple-pay-button-type: book;
}

.apple-pay-button-text-buy {
	-apple-pay-button-type: plain;
}

.apple-pay-button-text-check-out {
	-apple-pay-button-type: check-out;
}

.apple-pay-button-text-donate {
	-apple-pay-button-type: donate;
}

/* For mobile devices */

@media only screen and (max-width: 600px) {
	.apple-pay-button {
		width: 100%;
		height: 50px;
	}
}