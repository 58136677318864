.submitBtn {
  margin-top: 3vh;
}

.flexContainer {
  display: flex;
  color: var(--white);
  justify-content: space-between;
  align-items: center;
}

.locationSpinner {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5vh;
  width: 100%;
}