.trackingInputContainer {
  display: flex;
  align-items: center;
  background-color: var(--white);
  width: 100%;
  border-radius: 7px;
  border: 2px solid var(--primary-blue);
}

.trackingInputContainer:focus-within {
  border: 2px solid var(--secondary-yellow);
}

.trackingInputContainer:focus-within input {
  background-image: unset;
}

.input {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  border: 0;
  flex-grow: 1;
  background-color: transparent;
  outline: none;
}

.input:focus {
  border: none;
  border-width: 0;
}

.searchBtn {
  background-color: transparent;
  padding: 5px 15px;
  border: 0;
  border-left: 1px solid var(--primary-blue);
  border-right: 1px solid var(--primary-blue);
  border-radius: 0;
  text-align: center;
  display: inline-block;
  height: 100%;
  width: unset;
  outline: none;
  margin: 0;
}

.searchBtnEmpty {
  opacity: .5;
}

.trackingInput {
  border: 0;
  border-radius: 0;
  margin: 0;
}

.partial {
  width: 45%;
}

.searchIcon {
  position: absolute;
  display: inline;
  background-color: transparent;
  z-index: 0;
  right: 20px;
  padding: 2px;
  border: 1px solid transparent;
  text-align: center;
  display: inline-block;}

.searchIcon::before {
  display: inline;
  font-style: normal;
  font-weight: normal;
}

.firstIcon {
  background-color: transparent;
  display: inline;
  padding: 0 8px 0 17px;
  font-size: 20px;
  font-weight: 600;
  color: var(--gray-01);
  white-space:nowrap;
}

.label {
  color: var(--white);
  margin-bottom: 5px;
  font-size: 0.8rem;
  display: inline-block;
}

.inputContainer {
  margin-bottom: var(--page-item-bottom-margin);
  position: relative;
}

.inputContainer:not(.partial) {
  width: 100%;
}

.label + .input {
  margin-top: 5px;
}

.input {
  display: flex;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
}

.input, .input:focus {
  border: 2px solid transparent;
}

.input input {
  margin: 0;
  background-color: transparent;
}

.input:focus-within {
  border: 2px solid var(--secondary-yellow);
  box-shadow: 0 2px 10px rgba(255, 194, 14, 0.5);
}

.input:focus-within input {
  background-image: none;
}

.trackingInput:focus-within {
  border: 0;
  box-shadow: none;
}

.input.disabled {
  background-color: var(--gray-02);
  border: 2px solid var(--gray-03);
}

.validationIcon {
  display: flex;
  align-items: center;
  padding: 0 0.5em;
}

.inputValidationPass, .inputValidationFail {
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
}

.inputValidationFail {
  border-color: var(--error);
}

.inputValidationPass {
  border-color: var(--success);
}

.errorShower {
  position: absolute;
  right: 0;
  height: 1.2rem;
  margin-top: 0.2rem;
}

.validationError {
  background-color: var(--error);
  font-weight: 700;
  padding: 5px;
  width: fit-content;
  color: var(--white);
  border-radius: 3px;
  margin-left: auto;
  font-size: 10px;
  z-index: 99;
}

.invalid {
  border: 2px solid var(--error);
}

.valid {
  border: 2px solid var(--success);
}

.validLTR  input,
.invalidLTR  input,
.disabledLTR  input {
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
}

.validRTL input,
.invalidRTL  input,
.disabledRTL  input {
  background-repeat: no-repeat;
  background-position-x: 5%;
  background-position-y: center;
}

.valid input {
  background-image:url(../../../icons/checked.svg);
}

.invalid input {
  background-image:url(../../../icons/cross.svg);
}

.disabled input {
  background-image:url(../../../icons/padlock.svg);
}

/* autocomplete */
.autocompleteContainer {
  width: 100%;
  position: relative;
}

.autocompleteContainer .input:focus-within {
  box-shadow: none;
  border-bottom: none;
  border-width: 1px;
} 

.autocompleteList {
  color: var(--black);
  font-size: 0.8rem;
  position: absolute;
  background-color: var(--white);
  z-index: 1;
  width: 100%;
  max-height: 180px;
  margin-top: -1vh;
  border-radius: 0 0 7px 7px;
  overflow-y: scroll;
}

.autocompleteList li {
  padding: 10px;
  border-top: 1px solid var(--gray);
  display: flex;
  align-items: center;
}

.autocompleteList li > svg {
  width: 15px;
  margin-right: 5px;
}

.focus {
  border: 1px solid var(--secondary-yellow);
  box-shadow: 0 2px 3px 1px rgba(255, 194, 14, 0.5);
}

.autocompleteList.focus {
  border-top: 0;
}

.input.focus {
  border-bottom: unset;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}