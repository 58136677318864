.textRTL {
    direction: rtl;
}

.imageLTR, svg.imageLTR, img.imageLTR {
    transform: scale(calc(var(--scale)));
}

.imageRTL, svg.imageRTL, img.imageRTL {
    transform: scaleX(calc(-1 * var(--scale))) scaleY(var(--scale));
}
