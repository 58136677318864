.mobileVerificationModal {
  line-height: 24px;
  padding-bottom: 50px;
  padding-top: 50px;
}

.mobileVerificationModalTitle {
  color: var(--primary-blue);
}

.mobileVerificationModalButton {
  margin-top: 30px;
}

.mobileVerificationImage {
  height: 90px;
}