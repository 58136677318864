.button {
  border-radius: 5px;
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
}

.lg {
  width: 100%;
  padding: 10px;
}

.sm {
  width: 80px;
}

.primary {
  background-color: var(--secondary-yellow);
  color: var(--blackish);
  border: 2px solid var(--blackish);
  font-weight: 600;
}

.secondary {
  background-color: var(--white);
  color: var(--black);
  border: 2px solid var(--secondary-yellow);
}

.chatWithDriverBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.chatWithDriverBtn > p {
  margin-right: 1px;
}

.chatWithDriverBtnLink {
  display: flex;
  font-size: 12px;
}

.chatWithDriverBtnLink .chatWithDriverBtn {
  margin-top: 0;
}

.chatWithDriverBtnLink,
.chatWithDriverBtnLink:active,
.chatWithDriverBtnLink:visited {
  text-decoration: none;
}

.loading > div {
  --spinner-height: 20px;
  margin: 0 auto;
}