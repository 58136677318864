:root {
  /* Fonts */
  --system-ui-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --default-font-family: Lato, var(--system-ui-font);
  --header-font-family: Cabin, var(--system-ui-font);


  /* colors */
  --white: #fff;
  --black: #000;
  --link: #4550E5;
  --blackish: #2F2F2F;
  --gray: rgba(0, 0, 0, 0.16);
  --primary-blue: #0A54A6;
  --secondary-blue: #0054A6;
  --secondary-yellow: #FFC20E;
  --funky-green: #88BBD7;
  --light-green: #B3E0D0;
  --light-blue: #65AFFF;
  --success: #57C864;
  --error: #F23F37;
  --gray-01: #B5B5B5;
  --gray-02: #f0f0f0;
  --gray-03: #707070;
  --deep-green: #858B5A;
  --accent-yellow: #FEC22D;

  /* lengths */
  --page-item-bottom-margin: 1vh;
}