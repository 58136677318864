.locationBtn {
  padding:  0;
  background-color: transparent;
  color: var(--secondary-yellow);
  font-size: 0.7rem;
  text-decoration: underline;
}

.locationBtn > svg {
  transform: translateY(15%);
  margin-right: 3px;
}

.locationModal {
  padding: 3vh 5vw 0;
}

.actionFlex {
  display: flex;
  justify-content: space-between;
}

.continueBtn {
  border-width: 2px;
  margin: 35px 0;
}

.actionBtn {
  composes: continueBtn;
  padding: 1.5vh 0;
  width: 48%;
  font-size: 0.8rem;
}

.cancelBtn {
  border: 2px solid var(--secondary-yellow);
  font-weight: 600;
}

.alertSVG {
  height: 13vh;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.flex > button {
  flex-basis: 48%;
}

.title {
  color: var(--primary-blue);
}